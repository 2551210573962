import React from 'react';
import { Text, Box, Button, ButtonProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';

export interface CustomerButtonProps extends ButtonProps {
  title: string;
}

/** Animated Customer Button */
export function CustomerButton({ title, ...props }: CustomerButtonProps) {
  return (
    <Button
      as={motion.button}
      variant="customer-outline"
      position="relative"
      whileHover="hover"
      whileFocus="focus"
      animate="rest"
      width="100%"
      maxWidth={770}
      {...props}
    >
      {/* Animated Background Box (width goes from 0 to 100)*/}
      <Box
        as={motion.div}
        position="absolute"
        left={0}
        top={0}
        height="100%"
        backgroundColor="primary.500"
        border={0}
        variants={{
          rest: { width: 0 },
          hover: {
            width: '100%',
            right: 0,
            transition: { ease: 'linear', duration: 0.3 },
          },
        }}
      ></Box>

      <Text
        as={motion.span}
        color="primary.500"
        zIndex={200}
        variants={{
          hover: {
            color: 'rgb(255, 255, 255)',
            transition: { ease: 'linear' },
          },
          focus: {
            color: 'rgb(255, 255, 255)',
          },
        }}
      >
        {title ? title : 'Customer Animated Button'}
      </Text>
    </Button>
  );
}
