import React, { useEffect } from 'react';
import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import {
  ClientSafeProvider,
  getCsrfToken,
  getProviders,
  getSession,
  LiteralUnion,
  signIn,
} from 'next-auth/react';
import { BuiltInProviderType } from 'next-auth/providers';
import { Box, Heading, HStack, VStack, Text, Divider, Stack } from '@chakra-ui/react';

import { documentTitle } from '@/utils/documentTitle';
import { EmailLoginForm } from '@/components/EmailLoginForm';
import { CustomerButton } from '@/components/CustomerButton';

const PROVIDER_BUTTON_TEXT = {
  'azure-ad': 'CNY Employee Login',
};

const MAX_WIDTH = ['90%', '90%', 500];

interface SignInProps {
  csrfToken: string;
  providers: Record<LiteralUnion<BuiltInProviderType, string>, ClientSafeProvider> | null;
}

function SignIn({ csrfToken, providers }: SignInProps) {
  const [hasEmailProvider, setHasEmailProvider] = React.useState(false);
  const [oauthProviders, setOauthProviders] = React.useState([]);

  useEffect(() => {
    const allOauthProviders = [];

    for (const id of Object.keys(providers)) {
      const provider = providers[id];

      if (provider.type === 'email') {
        setHasEmailProvider(true);
      } else if (provider.type === 'oauth') {
        allOauthProviders.push(provider);
      }
    }

    setOauthProviders(allOauthProviders);
  }, [providers]);

  return (
    <>
      <Head>
        <title>{documentTitle('Login')}</title>
      </Head>

      {hasEmailProvider && (
        <VStack
          align="stretch"
          border="1px"
          borderColor="gray.100"
          marginX="auto"
          maxW={MAX_WIDTH}
          px={6}
          py={8}
          shadow="lg"
          spacing={6}
        >
          <Heading variant="customer-heading" fontSize={28} fontWeight={500}>
            Patient Login
          </Heading>
          <Stack spacing={5}>
            <Text>
              Thank you for considering CNY Fertile Financing to support your family building
              journey.
            </Text>

            <Text>
              To access your monthly payment plan information, please enter your email address that
              received a quote from us. Within a few minutes, you’ll receive an automated email from{' '}
              <strong>strategy@cnyfertility.com</strong> with a secure link to access your CNY
              Monthly Payment Plan history (please check your junk, spam, and other inbox tabs.)
            </Text>
            <Text fontSize="sm">
              If our email went to your junk or spam, please add our email address to your contacts
              list to ensure delivery in the future.
            </Text>
          </Stack>
          <EmailLoginForm csrfToken={csrfToken} />
        </VStack>
      )}

      <HStack maxW={MAX_WIDTH} marginX="auto" mt="35px">
        <Divider borderColor={'primary.100'} />
        <Text color="primary.100">or</Text>
        <Divider borderColor={'primary.100'} />
      </HStack>

      {oauthProviders.length > 0 && (
        <VStack
          align="stretch"
          border="1px"
          borderColor="gray.100"
          marginX="auto"
          maxW={MAX_WIDTH}
          px={6}
          py={8}
          mt={10}
          shadow="lg"
          spacing={6}
        >
          <Heading variant="customer-heading" fontSize={28} fontWeight={500}>
            Employee Login
          </Heading>
          {oauthProviders.map((provider) =>
            provider.type === 'oauth' ? (
              <Box key={provider.id}>
                <CustomerButton
                  title={PROVIDER_BUTTON_TEXT[provider.id] || `Sign in with ${provider.name}`}
                  type="submit"
                  onClick={() => signIn(provider.id)}
                />
              </Box>
            ) : null
          )}
        </VStack>
      )}
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { req } = context;
  const session = await getSession({ req });

  if (session) {
    return {
      redirect: { destination: '/' },
    };
  }

  return {
    props: {
      providers: await getProviders(),
      csrfToken: await getCsrfToken(context),
    },
  };
}

export default SignIn;
