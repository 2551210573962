import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { CustomerButton } from './CustomerButton';

const ERROR_MESSAGES = {
  'user-not-found':
    'We could not find an account with that email address. Please contact CNY for further assistance.',
};

interface EmailLoginFormProps {
  csrfToken: string;
}

export function EmailLoginForm({ csrfToken }: EmailLoginFormProps) {
  const { query } = useRouter();

  return (
    <VStack as="form" align="stretch" method="post" action="/api/auth/signin/email" spacing={5}>
      {query.error && ERROR_MESSAGES[query.error as string] ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{ERROR_MESSAGES[query.error as string]}</AlertDescription>
        </Alert>
      ) : null}
      <FormControl>
        <FormLabel variant="customer-label" htmlFor="email">
          Email address
        </FormLabel>
        <Input variant="customer-input" id="email" name="email" type="email" required />
      </FormControl>
      <Input name="csrfToken" type="hidden" defaultValue={csrfToken} />
      <CustomerButton title="Patient Login" type="submit" />
    </VStack>
  );
}
